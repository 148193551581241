import { m } from 'framer-motion';
// next
import Head from 'next/head';
import NextLink from 'next/link';
// @mui
import { Box, Button, Typography, useTheme } from '@mui/material';
// layouts
import CompactLayout from '../app/screens/layouts/compact-layout';
// components
import { MotionContainer, varBounce } from '../app/screens/shared/components/animate';
import Image from 'next/image';
import { IMAGES_PREFIX } from '~config/config-global';

// ----------------------------------------------------------------------

Page404.getLayout = (page: React.ReactElement) => <CompactLayout>{page}</CompactLayout>;

// ----------------------------------------------------------------------

export default function Page404() {
  const {
    palette: {
      grey
    }
  }: any = useTheme();
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="404.tsx">
        <title> 404 Page Not Found | Songoven</title>
      </Head>

      <MotionContainer data-sentry-element="MotionContainer" data-sentry-source-file="404.tsx">
        <m.div variants={varBounce().in} data-sentry-element="unknown" data-sentry-source-file="404.tsx">
          <Typography variant="h3" paragraph data-sentry-element="Typography" data-sentry-source-file="404.tsx">
            Sorry, page not found!
          </Typography>
          <Typography color={grey[600]} variant="h6" fontWeight={'400'} paragraph data-sentry-element="Typography" data-sentry-source-file="404.tsx">
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </Typography>{' '}
        </m.div>

        <m.div variants={varBounce().in} data-sentry-element="unknown" data-sentry-source-file="404.tsx">
          <Box display="flex"
        //  bgcolor={primary.creamLight2}
        justifyContent="center" alignItems="center" borderRadius={4} mt={2} data-sentry-element="Box" data-sentry-source-file="404.tsx">
            <Image unoptimized alt="auth" width={652} src={IMAGES_PREFIX + '/assets/images/ordercomplete/lastStep.png'} height={1} data-sentry-element="Image" data-sentry-source-file="404.tsx" />
          </Box>
        </m.div>

        <Button sx={{
        marginTop: 10
      }} component={NextLink} href="/" size="large" variant="contained" data-sentry-element="Button" data-sentry-source-file="404.tsx">
          Back to homepage
        </Button>
      </MotionContainer>
    </>;
}