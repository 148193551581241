import { IMAGES_PREFIX } from './app/config/config-global';
import {
  mergeCloudinaryParamsStrings,
  splitBy2,
} from './app/config/helper/index';

// Demo: https://res.cloudinary.com/demo/image/upload/w_300,c_limit,q_auto/turtles.jpg

//  https://res.cloudinary.com/defenderkhan/image/upload/Img_Cover_L.8birthday_dmaapb.png
//  https://res.cloudinary.com/defenderkhan/image/upload/v1697436241/Img_Cover_L.8birthday_dmaapb.png
//  https://res.cloudinary.com/defenderkhan/image/upload/w_300,c_limit,q_auto/v1697436241/Img_Cover_L.8birthday_dmaapb.png

export default function cloudinaryLoader({ src, width, quality }: any) {
  const params = ['f_auto', 'c_limit', `w_${width}`, `q_${quality || 'auto'}`];
  let str_params = params.join(',');

  // not sure why is this if and _next static media is requried:
  // if (src.includes('/_next/static/media')) {
  //   return src;
  // }

  if (src.startsWith(IMAGES_PREFIX)) src = src.substring(IMAGES_PREFIX.length);

  if (src.startsWith('/')) {
    // files in public folder, are at / in the url
    if (src.startsWith('/')) src = src.substring(1);

    // return `https://res.cloudinary.com/songoven/image/upload/${str_params}/website_assets/client.songoven.com/${src}`

    // IMAGES_PREFIX // is like https://res.cloudinary.com/songoven/image/upload/website_assets/client.songoven.com
    let [part1, part2] = IMAGES_PREFIX.split('/upload/');
    // part1 is like  https://res.cloudinary.com/songoven/image   it needs also /upload/
    // part2 is like  website_assets/client.songoven.com it needs / at end

    if (src.endsWith('.svg')) {
      return `${part1}/upload/${part2}/${src}`;
    }

    const url = `${part1}/upload/${str_params}/${part2}/${src}`;

    const url_webp = url.endsWith('.webp')
      ? url
      : url.substring(0, url.lastIndexOf('.')) + '.webp';
    return url_webp;
  } else if (src.endsWith('.svg')) {
    return src;
  } else if (src.startsWith('https://res.cloudinary.com/')) {
    if (src.includes('/main/')) return src;

    const params2 = [`w_${width}`, `q_${quality || 'auto'}`];
    let str_params2 = params2.join(',');

    // prefix is like 'https://res.cloudinary.com/songoven/image'  , path is like 'w_300,c_limit,q_auto/v1697436241/Img_Cover_L.8birthday_dmaapb.png'
    const [prefix, path] = splitBy2(src, '/upload/');
    // maybe_params is like  'w_300,c_limit,q_auto'  , _rest_of_path is like 'v1697436241/Img_Cover_L.8birthday_dmaapb.png'
    const [maybe_params, rest_of_path] = splitBy2(path || '', '/');
    // if there is ',' in first url-literal assume it has params
    const it_already_has_params = maybe_params.includes(',');
    const str_params3 = it_already_has_params
      ? mergeCloudinaryParamsStrings(str_params2, maybe_params)
      : str_params2 + '/' + maybe_params;
    const url = `${prefix}/upload/${str_params3}/${rest_of_path}`;

    const url_webp = url.endsWith('.webp')
      ? url
      : url.substring(0, url.lastIndexOf('.')) + '.webp';
    // console.log(url, [{ prefix }, '/upload/', { str_params: str_params2 }, { it_already_has_params }, { path }])
    return url_webp;
  }

  return src;
}