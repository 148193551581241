import dynamic from 'next/dynamic';
import { Stack, Container } from '@mui/material';
import useOffSetTop from '~hooks/useOffSetTop';
import { HEADER } from '~config/config-global';
import LoginModals from '../../shared/modal-dialogs/login-modals';
type Props = {
  children?: React.ReactNode;
  help?: any;
  removeNav?: any;
  sx?: any;
};
const Header = dynamic(() => import('./Header'), {
  ssr: false
});
function CompactLayout({
  children,
  removeNav,
  sx,
  help = true
}: Props) {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  return <div data-sentry-component="CompactLayout" data-sentry-source-file="CompactLayout.tsx">
      {!removeNav && <Header help={help} />}
      <Container className="compact-container" component="main" sx={{
      ...sx
    }} data-sentry-element="Container" data-sentry-source-file="CompactLayout.tsx">
        <Stack sx={{
        py: 12,
        m: 'auto',
        maxWidth: 600,
        minHeight: '100vh',
        textAlign: 'center',
        justifyContent: 'center'
      }} data-sentry-element="Stack" data-sentry-source-file="CompactLayout.tsx">
          {children}
        </Stack>
      </Container>
      <LoginModals data-sentry-element="LoginModals" data-sentry-source-file="CompactLayout.tsx" />
    </div>;
}
export default CompactLayout;